<template>
  <div class="third">
    <div
      class="add"
      @click="handelAdd">
      新增配置
    </div>
    <bc-table
      :table-header="tableHeader"
      :table-data="tableData"
      :loading="loading">
      <template v-slot:operate>
        <el-table-column
          label="操作"
          align="center"
          width="150">
          <template slot-scope="{ row }">
            <el-button
              type="primary"
              size="mini"
              @click="handelEdit(row)">
              编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </bc-table>

    <bc-dialog
      width="750px"
      :visible="isShowDialog"
      :title="type === 'add' ? '新增配置' : '编辑配置'"
      @confirm="handleConfirm"
      @cancel="handleCancle">
      <template v-slot:dialogContent>
        <el-form
          ref="editorForm"
          class="editor-form"
          :model="form"
          :rules="rules"
          label-width="150px"
          label-position="right">
          <el-form-item
            prop="name"
            label="名称">
            <el-input
              v-model.trim="form.name"
              placeholder="请输入名称">
            </el-input>
          </el-form-item>
          <el-form-item
            prop="from"
            label="来源">
            <el-select
              v-model="form.from"
              placeholder="请选择"
              :disabled="type === 'edit'"
              style="width: 100%"
              @change="formChange">
              <el-option
                v-for="item in formLists"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="models"
            label="模块">
            <el-checkbox-group
              v-model="form.models"
              @change="handelChangeModels">
              <el-checkbox
                v-for="(item, index) in modelsType"
                :label="item.type"
                :disabled="item.disabled"
                :key="index"
                >{{ item.label }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            v-if="type === 'add'"
            prop="from"
            label="关联应用">
            <el-select
              v-model="form.bind_app_id"
              placeholder="请选择"
              style="width: 100%"
              @change="formChange">
              <el-option
                v-for="item in appLists"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="form.from === 1"
            prop="web_config_file"
            label="web应用配置文件"
            :key="1">
            <div class="flex">
              <base-upload
                :accept="accept"
                :fileList="webFileList"
                :on-remove="removeWeb"
                @success="webUploadSuccess">
                <i class="el-icon-upload2"></i>
              </base-upload>
              <a
                :href="ggLoginReadUrl"
                target="_blank"
                style="margin-left: 20px"
                >获取方法</a
              >
            </div>
            <span v-if="webFileList.length <= 0">{{ form.client_id }}</span>
          </el-form-item>
          <el-form-item
            :key="2"
            v-if="form.models.includes('pay') && form.from === 1"
            prop="service_config_file"
            label="服务帐号配置文件">
            <div class="flex">
              <base-upload
                :accept="accept"
                :fileList="serviceFileList"
                :on-remove="removeService"
                @success="serviceUploadSuccess">
                <i class="el-icon-upload2"></i>
              </base-upload>
              <a
                :href="ggPayReadUrl"
                target="_blank"
                style="margin-left: 20px"
                >获取方法</a
              >
            </div>
            <span v-if="serviceFileList.length <= 0">{{
              form.service_client_id
            }}</span>
          </el-form-item>
          <el-form-item
            label="配置选项"
            v-if="form.from !== 1"
            required>
            <div
              v-for="(items, index) in form.extra_json"
              :key="index">
              <el-row :gutter="22">
                <el-col
                  class="input-item-name"
                  :span="6"
                  :style="index === 0 ? 'margin-left:-10px;' : ''">
                  <el-form-item>
                    <span>{{ items.name }}</span>
                  </el-form-item>
                </el-col>
                <el-col
                  class="input-item"
                  :span="13">
                  <el-form-item
                    :prop="'extra_json.' + index + `.value`"
                    :rules="{
                      required: items.required,
                      message: `请输入${items.name}`,
                      trigger: 'blur',
                    }">
                    <el-input
                      @input="onChange"
                      v-model.trim="items.value"
                      placeholder="请输入字段值">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col
                  class="input-item-name"
                  :span="4">
                  <a
                    :href="items.readme_url"
                    target="_blank"
                    >获取方法</a
                  >
                </el-col>
              </el-row>
            </div>
          </el-form-item>
        </el-form>
      </template>
    </bc-dialog>
  </div>
</template>

<script>
import BaseUpload from '@/components/Upload.vue';
import {
  getThirdsList,
  thirdAdd,
  thirdDel,
  thirdEdit,
  getFromList,
  getThirdInfo,
  getAppList,
} from '@/api/application';
import ClipboardJS from 'clipboard';
let rule = null;
let basicRules = null;
export default {
  name: 'third',
  components: {
    BaseUpload,
  },
  data() {
    rule = {
      web_config_file: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (
              value === '' ||
              this.webFileList.length <= 0 ||
              this.form.web_config_file === ''
            ) {
              callback(new Error('请上传文件'));
            } else {
              callback();
            }
          },
        },
      ],
      service_config_file: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (
              value === '' ||
              this.serviceFileList.length <= 0 ||
              this.form.service_config_file === ''
            ) {
              callback(new Error('请上传文件'));
            } else {
              callback();
            }
          },
        },
      ],
    };
    basicRules = {
      name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      from: [{ required: true, message: '请输入来源', trigger: 'blur' }],
      models: [{ required: true, message: '请选择模块', trigger: 'change' }],
    };
    return {
      loading: false,
      form: {
        from: '1',
        models: ['login'],
        web_config_file: '',
        service_config_file: '',
        name: '',
        bind_app_id: '',
      },
      tableHeader: [
        {
          prop: 'name',
          label: '名称',
        },
        {
          prop: 'bind_apps',
          label: '已关联应用',
        },
        {
          prop: 'from_name',
          label: '渠道',
        },
        {
          prop: 'models',
          label: '模块',
        },
        {
          prop: 'client_id',
          label: '相关参数',
          'min-width': '350',
          render: (h, row) => {
            return row.client_id !== '' && row.client_id != null ? (
              <div>
                <span style={'font-weight:bold;margin-right:5px'}>
                  {row.from === 1 ? `OAuth客户端ID:` : `APPID:`}
                </span>
                {row.client_id}
                <i
                  class='el-icon-document-copy'
                  id='copy'
                  style={
                    'margin-left:20px;font-size:16px;color:#5b03e4;cursor:pointer;'
                  }
                  onClick={() => this.handleCopy(row.client_id)}></i>
              </div>
            ) : (
              ''
            );
          },
        },
        {
          prop: 'service_client_id',
          label: '服务账号ID',
        },
        {
          prop: '',
          label: '授权状态',
          render: (h, row) => {
            return row.is_authorized === 1 ? (
              <span class={'authorized'}>已授权</span>
            ) : row.is_authorized === -1 ? (
              <span>无需授权</span>
            ) : (
              <div class='fa'>
                还未授权，去授权
                <i class='far fa-hand-point-right' />
                <span
                  onClick={() => this.handleLink(row.authurl)}
                  class={[
                    'logo-icon',
                    row.from === 1 ? 'google' : 'apple',
                  ]}></span>
              </div>
            );
          },
        },
      ],
      tableData: [],
      isShowDialog: false,
      rules: {
        // name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        // from: [{ required: true, message: '请输入来源', trigger: 'blur' }],
        // models: [{ required: true, message: '请选择模块', trigger: 'change' }],
      },
      type: 'add',
      secretShow: false,
      accept: 'application/json',
      serviceFileList: [],
      webFileList: [],
      modelsType: [
        { label: '登录', type: 'login', disabled: true },
        { label: '支付', type: 'pay', disabled: false },
      ],
      client_id: 0,
      formLists: [],
      appLists: [],
      editInfo: {},
      ggLoginReadUrl: '',
      ggPayReadUrl: '',
    };
  },
  watch: {
    type: {
      handler() {
        if (this.form.from === '1') {
          this.rules = { ...rule, ...basicRules };
        } else {
          this.rules = rule;
        }
        this.getAppLists(1);
      },
      immediate: true,
    },
    isShowDialog: {
      handler(newValue) {
        if (newValue) {
          this.getFromLists();
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 复制成功时的回调函数
    async handleCopy(client_id) {
      this.client_id = client_id;
    },
    handleConfirm() {
      this.$refs['editorForm'].validate((valid) => {
        if (valid) {
          this.isShowDialog = false;
          this.type === 'add' ? this.addData() : this.editData();
        } else {
          return false;
        }
      });
    },
    handleCancle() {
      this.isShowDialog = false;
      this.$refs['editorForm'].resetFields();
    },
    handelSecrect() {
      this.secretShow = !this.secretShow;
    },
    handelAdd() {
      this.type = 'add';
      this.isShowDialog = true;
      this.serviceFileList = [];
      this.webFileList = [];
      this.editInfo = {};
      this.$nextTick(() => {
        this.form = {
          bind_app_id: '',
          name: '',
          from: 1,
          models: ['login'],
          web_config_file: '',
          service_config_file: '',
          extra_json: [
            {
              name: '',
              value: '',
            },
          ],
        };
        this.$refs['editorForm'].resetFields();
      });
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          thirdDel({ id: row.id }).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
              this.loadData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    getFileName(path) {
      const lastIndex = path.lastIndexOf('/');
      return path.substring(lastIndex + 1);
    },
    handelEdit(item) {
      this.type = 'edit';
      this.serviceFileList = [];
      this.webFileList = [];
      this.getInfo(item);
      this.isShowDialog = true;
      this.$nextTick(() => {
        this.$refs['editorForm'].resetFields();
      });
    },
    extraJson() {
      const extra_json = this.form.extra_json.reduce((acc, item) => {
        if (item.name !== '' && item.value !== '') {
          acc[item.name] = item.value;
          return acc;
        }
      }, {});
      return JSON.stringify(extra_json) || '';
    },
    addData() {
      const model = this.form.models;
      this.form.models = model.join(',');
      this.form.extra_json = this.extraJson();
      thirdAdd(this.form).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.loadData();
        }
      });
    },
    editData() {
      const model = this.form.models;
      this.form.models = model.join(',');
      this.form.extra_json = this.extraJson();
      thirdEdit(this.form).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.loadData();
        }
      });
    },
    loadData() {
      this.loading = true;
      getThirdsList({
        page: 1,
        limit: 10000,
      }).then((res) => {
        this.tableData = res.data.list;
        this.loading = false;
      });
    },
    serviceUploadSuccess(file) {
      this.form.service_config_file = file.response.data.filename;
      this.serviceFileList = [
        {
          url: file.response.data.filename,
          name: file.name,
        },
      ];
      this.$refs.editorForm.clearValidate('service_config_file');
    },
    webUploadSuccess(file) {
      this.form.web_config_file = file.response.data.filename;
      this.webFileList = [
        {
          url: file.response.data.filename,
          name: file.name,
        },
      ];
      this.$refs.editorForm.clearValidate('web_config_file');
    },
    removeWeb() {
      this.webFileList = [];
      this.$refs.editorForm.validateField('web_config_file');
    },
    removeService() {
      this.serviceFileList = [];
      this.$refs.editorForm.validateField('service_config_file');
    },
    handleLink(url) {
      if (url === '') {
        this.$message.warning('授权链接不存在，无法授权');
      } else {
        window.open(url, '_self');
      }
    },
    getFromLists() {
      getFromList({
        page: 1,
        limit: 1000,
        status: 1,
      }).then((res) => {
        this.formLists = res.data;
        this.ggLoginReadUrl = res.data.find(
          (item) => item.id === this.form.from
        ).login.fields[0].readme_url;
        this.ggPayReadUrl = res.data.find(
          (item) => item.id === this.form.from
        ).pay.fields[0].readme_url;
      });
    },
    onChange() {
      this.$forceUpdate();
    },
    formChange(v) {
      if (v === '1') {
        this.form.extra_json = [];
        this.rules = {
          ...rule,
          ...basicRules,
        };
      } else {
        this.rules = basicRules;
        this.$refs.editorForm.clearValidate('service_config_file');
        this.$refs.editorForm.clearValidate('web_config_file');
        this.getModelConfig();
      }
      this.getAppLists(v);
    },
    getInfo(row) {
      getThirdInfo({
        id: row.id,
      }).then((res) => {
        this.form = res.data;
        if (this.form.extra_json !== null && this.form.from !== 1) {
          this.form.extra_json = res.data.extra_json;
        } else {
          this.form.extra_json = [{ name: '', value: '' }];
        }
        this.form.models = res.data.models.split(',');
        this.editInfo = JSON.parse(JSON.stringify(this.form));
      });
    },
    getAppLists(v) {
      getAppList({
        page: 1,
        limit: 1000,
        bind_from: v,
      }).then((res) => {
        this.appLists = res.data.list;
      });
    },
    handelChangeModels() {
      this.getModelConfig();
    },
    getModelConfig() {
      let fieldsArr = [];
      let activeConfigList = null;
      activeConfigList = this.formLists.find(
        (item) => item.id === this.form.from
      );
      if (this.form.models.length === 1) {
        fieldsArr = activeConfigList.login.fields;
      } else if (this.form.models.length === 2) {
        fieldsArr = [
          ...activeConfigList.login.fields,
          ...activeConfigList.pay.fields,
        ];
      }
      if (this.type !== 'edit' || this.form.from !== this.editInfo.from) {
        this.form.extra_json = fieldsArr.map((v) => {
          return {
            name: v.name,
            value: '',
            readme_url: v.readme_url,
            required: v.required,
          };
        });
      } else {
        const fields = this.editInfo.extra_json.filter((obj1) =>
          fieldsArr.some(
            (obj2) => obj2.name === obj1.name && obj2.desc === obj1.desc
          )
        );
        this.form.extra_json = fields;
      }
    },
  },
  mounted() {
    this.loadData();
    const that = this;
    const clipboard = new ClipboardJS('#copy', {
      text: () => {
        return that.client_id;
      },
    });
    clipboard.on('success', () => {
      this.$message.success('内容已复制到剪切板！');
    });
    clipboard.on('error', () => {
      this.$message.error('抱歉，复制失败！');
    });
  },
};
</script>
<style lang="scss">
.input-item-name .el-form-item__content {
  text-align: center;
}
.third {
  .google {
    background: url(~@/assets/images/google.png) no-repeat;
    background-size: 100%;
  }
  .apple {
    background: url(~@/assets/images/iOS.png) no-repeat;
    background-size: 100%;
  }
  .authorized {
    color: #67c23a;
  }
  .logo-icon {
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
<style lang="scss" scoped>
.third {
  margin: 0 auto;
  font-size: 14px;
  padding: 30px;
  // min-height: 800px;
  // background: url(~@/assets/images/banner-left.png) no-repeat;
  .add {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #5b03e4;
    color: #fff;
    text-align: center;
    min-width: 100px;
    float: right;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .lists {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    div {
      line-height: 35px;
    }
  }
  .list-item {
    border: 1px solid #f3d7ff;
    border-radius: 23px;
    padding: 30px;
    cursor: pointer;
    width: 402px;
    margin: 15px;
    .long-text {
      overflow-wrap: break-word;
    }
    .icon-edit {
      color: #5b03e4;
      font-size: 24px;
      float: right;
    }
  }
}
</style>
